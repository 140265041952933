<template>
  <div
    style="
      width: 100%;
      height: 100%;
      position: relative;
      min-width: 800px;
      min-height: 600px;
    "
  >
    <img
      src="../../assets/background.png"
      alt=""
      style="width: 100%; height: 100%; min-width: 800px; min-height: 600px"
    />

    <div
      class="box"
      style="
        position: absolute;
        width: 80%;
        height: 80%;

        top: 10%;
        left: 10%;
        display: flex;
        border-radius: 24px;
        overflow: hidden;
      "
    >
      <div style="width: 50%; height: 100%; display: flex">
        <img
          src="../../assets/left.png"
          alt=""
          style="width: 100%; height: 100%"
        />
      </div>
      <div
        class="login"
        v-if="showPrise"
        style="width: 50%; background: #fff; padding: 20px; position: relative"
      >
        <h2
          style="
            color: #e24f00f2;
            font-weight: bold;
            font-size: 30px;
            position: absolute;
            left: 40%;
            top: 38%;
            margin-left: -60px;
            margin-top: -60px;
          "
        >
          恭 喜 您 注 册 成 功！
        </h2>
        <a
          href="http://msq.yoojet.com/Login?tap=1"
          style="
            padding: 70px 0 0 0;
            font-weight: 600;
            text-align: center;
            font-size: 30px;
            position: absolute;
            left: 46%;
            top: 42%;
            margin-left: -58px;
            margin-top: -52px;
          "
        >
          点击跳转登录</a
        >

        <!-- 视频播放 -->
        <a
          href=""
          style="
            padding: 70px 0 0 0;
            font-weight: 600;
            text-align: center;
            font-size: 20px;
            position: absolute;
            left: 47.5%;
            top: 62%;
            margin-left: -58px;
            margin-top: -52px;
          "
        >
          点击观看视频教学
        </a>
      </div>
      <div
        v-show="showRentPrise"
        id="register"
        style="width: 50%; background: #fff; padding: 20px; position: relative"
      >
        <el-card
          class="box-card"
          style="
            width: 80%;
            height: 88%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            /* overflow-y: auto; */
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
            "
          >
            <p
              class="title"
              style="
                font-weight: bold;

                font-size: 25px;
                margin-bottom: 0;
              "
            >
              用户注册
            </p>
            <a style="font-size: 18px" href="http://msq.yoojet.com/Login?tap=1"
              >已有账号？点此登录</a
            >
          </div>

          <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm">
            <el-form-item
              prop="tel"
              v-show="Codedisabled"
              style="position: relative"
            >
              <el-input
                prefix-icon="el-icon-mobile-phone"
                v-model="ruleForm2.tel"
                auto-complete="off"
                placeholder="请输入手机号"

              ></el-input>
            </el-form-item>

            <el-form-item prop="tel" v-show="!Codedisabled">
              <el-input
                prefix-icon="el-icon-mobile-phone"
                v-model="ruleForm2.tel"
                auto-complete="off"
                readonly="true"
              >
                <!-- placeholder="请输入手机号" -->
              </el-input>
            </el-form-item>
            <el-form-item prop="word" v-show="!Codedisabled">
              <el-input
                prefix-icon="el-icon-tickets"
                v-model="ruleForm2.word"
                auto-complete="off"
                placeholder="手机验证码"
                style="margin-top: -10px"

              >
              </el-input>
            </el-form-item>
            <el-form-item prop="pass" v-show="!Codedisabled">
              <el-input
                prefix-icon="el-icon-edit"
                :type="flag ? 'text' : 'password'"
                v-model="ruleForm2.pass"
                auto-complete="off"
                placeholder="输入密码"
              >
                <i
                  slot="suffix"
                  :class="flag ? 'el-icon-minus' : 'el-icon-view'"
                  style="margin-top: 8px; font-size: 18px"
                  autocomplete="auto"
                  @click="flag = !flag"
                />
              </el-input>
            </el-form-item>
            <el-form-item prop="checkPass" v-show="!Codedisabled">
              <el-input
                prefix-icon="el-icon-edit"
                :type="resetflag ? 'text' : 'password'"
                v-model="ruleForm2.checkPass"
                auto-complete="off"
                placeholder="确认密码"
              >
                <i
                  slot="suffix"
                  :class="resetflag ? 'el-icon-minus' : 'el-icon-view'"
                  style="margin-top: 8px; font-size: 18px"
                  autocomplete="auto"
                  @click="resetflag = !resetflag"
                />
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox
                v-show="Codedisabled"
                style="padding: 0px 0px 4% 0px"
                v-model="checked"
                >我已阅读并同意<a
                  href="psq.yoojet.com/#/Article"
                  target="_blank"
                  >使用条款</a
                >
              </el-checkbox>
              <el-button
                class="rBtn"
                type="primary"
                @click="submitForm(Codedisabled ? '发送验证码' : '注册')"
                style="width: 100%"
                >{{ Codedisabled ? "发送验证码" : "注册" }}</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>
    <el-dialog
      title="请输入图形验证码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <div style="display: flex; align-items: center">
        <el-input
          prefix-icon="el-icon-picture-outline "
          v-model="ruleForm2.smscode"
          placeholder="验证码"
          style="width: calc(100% -120px)"
        ></el-input>

        <div style="width: 120px; height: 40px" @click="yzmclick">
          <Yzm @strchange="strchange" :identifyCode="key"></Yzm>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCode">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Yzm from "./list";

let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
export default {
  data() {

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      timer: null,
      oneMinute: 60,
      showPrise: false,
      showRentPrise: true,
      flag: false, // 密码可视
      resetflag: false, // 密码可视
      checked: true,
      disabled: true,
      Codedisabled: true,
      ruleForm2: {},
      rules2: {
        smscode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        tel: [
          {
            required: true,
            message: "请输入手机号码",
            validator: checkPhone,
            trigger: "blur",
          },
        ],
        word: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      buttonText: "",
      isDisabled: false,
      yanzhengma: "",
      key: "",
    };
  },
  props: {},
  components: {
    Yzm,
  },

  methods: {
    isPhone() {
      var reg =
        /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
      if (this.number.length == 11) {
        if (!reg.test(this.number)) {
          this.isShow = true;
        } else {
          this.disabled = false;
          console.log("电话号码输入正确！");
        }
      } else {
        this.disabled = true;
        this.isShow = false;
      }
    },

    submitForm(type) {
      console.log(type);
      if (type == "发送验证码") {
        if (!this.checked) return this.$message.error("请勾选同意使用条款");
        this.$refs.ruleForm.validateField("tel", (valid) => {
          if (valid) {
            return;
          }
          this.dialogVisible = true;
        });
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          this.$message.error({
            showClose: true,
            message: "请填写完成后注册",
            type: "error",
            duration: 0,
          });
          // this.$message.error("请填写完成后注册");
          return;
        }
        this.$http
          .post("/Base_Manage/Base_User/SaveUserModel", {
            UserPhone: this.ruleForm2.tel,
            Password: this.ruleForm2.checkPass,
            SmsCode: this.ruleForm2.word,
          })
          .then((res) => {
            if (res.Success) {
              this.$message.success(res.Msg);
              // this.$router.push("/Login");
              this.showPrise = true;
              this.showRentPrise = false;
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    getCode() {
      if (this.ruleForm2.smscode.toUpperCase() === this.key.toUpperCase()) {
        this.dialogVisible = false;
        this.$http
          .post("/Base_Manage/Base_User/SaveRegister", {
            Phone: this.ruleForm2.tel,
          })
          .then((res) => {
            if (res.Success) {
              this.$message.success("验证码已经发送至您的手机,请注意查收");
              this.Codedisabled = false;
            } else {
              this.$message.error(res.Msg);
            }
          });
      } else {
        this.$message.error("请输入正确的验证码");
      }
    },
    yzmclick() {
      this.getYzm();
    },
    getYzm() {
      var str =
        "qwertyupkjhgfdsazxcvbnm23456789QWERTYUPKJHGFDSAZXCVBNM";
      var Yzm = "";
      for (var i = 0; i <= 3; i++) {
        var item = parseInt(Math.random() * (str.length - 1 + 0 + 1) - 0);
        Yzm += str[item];
      }
      this.key = Yzm;
    },
    getComputedStyle() {
      this.$http.post("/Base_Manage/Home/SubmitLogin", {
        userName: this.ruleForm.user,
        password: this.ruleForm.password,
      });
    },

    strchange(val) {
      console.log(val);
      this.key = val;
    },
    smscodechange(text) {
      if (text.toUpperCase() === this.key.toUpperCase()) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
  mounted() {
    this.getYzm();
  },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/ .el-input--prefix .el-input__inner {
  padding-left: 40px;
  border-radius: 15px;
}
/deep/ .el-input {
  position: relative;
  font-size: 15px;
}

/deep/ .el-form-item__content {
  line-height: 50px;
  position: relative;
  font-size: 14px;
}

/deep/ .el-card__body,
.el-main {
  padding: 60px;
}
/deep/ .rBtn {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  height: 45px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 18px;
}
/deep/ .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 18px;
}
/deep/ .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  margin-top: -4px;
  border-radius: 2px;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
/deep/.el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 9px;
  left: 6px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
</style>
